import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
import RequestTypeIntro from "../shared/components/requestTypeIntro";
function Delete() {
  return (
    <div>
      <SrrHeader
        heading="Delete Personal Information"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="Delete"
      />

      <RequestTypeIntro requestType="delete" />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_IMPARTNER_SRR_CONFIG_USA_DELETE_MYSELF}
          pagId={
            process.env.REACT_APP_IMPARTNER_SRR_CONFIG_USA_DELETE_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default Delete;
