import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
import RequestTypeIntro from "../shared/components/requestTypeIntro";
function RestrictProcessing() {
  return (
    <div>
      <SrrHeader
        heading="Restrict Processing Personal Information"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="Restrict Processing"
      />

      <RequestTypeIntro requestType="restrictProcessing" />

      <section className="form-section">
        <ImportScript
          src={
            process.env
              .REACT_APP_IMPARTNER_SRR_CONFIG_EU_RESTRICT_PROCESSING_MYSELF
          }
          pagId={
            process.env
              .REACT_APP_IMPARTNER_SRR_CONFIG_EU_RESTRICT_PROCESSING_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default RestrictProcessing;
