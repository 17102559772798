import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
import RequestTypeIntro from "../shared/components/requestTypeIntro";

function Access() {
  return (
    <div>
      <SrrHeader
        heading="Access Personal Information"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="Access"
      />

      <RequestTypeIntro requestType="access" />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_IMPARTNER_SRR_CONFIG_USA_ACCESS_MYSELF}
          pagId={
            process.env.REACT_APP_IMPARTNER_SRR_CONFIG_USA_ACCESS_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default Access;
