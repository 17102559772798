import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
import RequestTypeIntro from "../shared/components/requestTypeIntro";

function Correction() {
  return (
    <div>
      <SrrHeader
        heading="Update Personal Information"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="Correction"
      />

      <RequestTypeIntro requestType="update" />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_IMPARTNER_SRR_CONFIG_USA_CORRECTION_MYSELF}
          pagId={
            process.env
              .REACT_APP_IMPARTNER_SRR_CONFIG_USA_CORRECTION_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default Correction;
