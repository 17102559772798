import React, { useState } from "react";
import { Container, Button, DropdownButton, Dropdown } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import { Link } from "react-router-dom";
export default SubmitRequest;

function SubmitRequest() {
  const [USAActive, setUSAActive] = useState(true);
  const [EUActive, setEUActive] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("USA");

  const handleClick = (e) => {
    if (e.target.innerText === "USA") {
      setUSAActive(true);
      setEUActive(false);
      setDropdownValue("USA");
    } else {
      setUSAActive(false);
      setEUActive(true);
      setDropdownValue("EU");
    }
  };
  return (
    <>
      <SrrHeader
        heading="Submit Data Subject Request"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="SUBMIT DATA SUBJECT REQUEST"
      />

      <Container className="submit-request mt-4">
        <div className="mb-3 mt-4">
          <p>
            Impartner is committed to the responsible collection and use of
            personal information. If you would like to access, delete, or opt
            out of the sale of personal information, please choose from the
            choices below.
          </p>
        </div>
        <section>
          <p>
            <strong>Please select region of residence</strong>
          </p>

          <DropdownButton
            id="dropdown-basic-button"
            title={dropdownValue}
            className="mb-4"
          >
            <Dropdown.Item onClick={handleClick}>USA</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>EU</Dropdown.Item>
          </DropdownButton>
        </section>

        {USAActive && (
          <section id="usa" className="mb-5">
            <p>
              If you are a U.S. resident, you may make certain requests
              regarding your personal information. We will fulfill each of these
              requests to the extent required by law.
            </p>
            <p>
              <strong>Access</strong> - Please select the “Access” button below
              to request information about the categories and specific pieces of
              personal information Impartner has collected about you, the
              categories of sources from which such information is collected,
              the purpose for collecting such information, and/or the categories
              of third parties with whom we share such information.
            </p>
            <p>
              <strong>Delete</strong> - Please select the “Delete” button below
              to request that Impartner delete your personal information from
              its systems.
            </p>
            <p>
              <strong>Do Not Sell</strong> - Impartner does not sell your
              personal information for money. We do share personal information
              with certain third parties in a way that may be considered a
              “sale” under certain U.S. laws, such as the California Consumer
              Privacy Act of 2018. You may request that Impartner cease sharing
              your personal information with such third parties by selecting the
              “Do Not Sell” button below.
            </p>
            <p>
              <strong>Correction</strong> - You may request that your personal
              information be corrected in our systems by selecting the
              “Correction” button below.
            </p>
            <section className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link className="selfLink" to={`USA/access`} target="_blank">
                    <Button variant="primary"> Access </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`USA/delete`} target="_blank">
                    <Button variant="primary" className="list-button">
                      {" "}
                      Delete{" "}
                    </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`USA/donotsell`}
                    target="_blank"
                  >
                    <Button variant="primary" className="list-button">
                      {" "}
                      Do Not Sell{" "}
                    </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`USA/correction`}
                    target="_blank"
                  >
                    <Button variant="primary"> Correction </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}

        {EUActive && (
          <section id="eu" className="mb-5">
            <p>
              If you are a EU resident, you may make certain requests regarding
              your personal information. We will fulfill each of these requests
              pursuant to the requirements of applicable law.
            </p>
            <p>
              <strong>Access / Portability</strong> - Please select the “Access
              / Portability” button below to request that Impartner provide you
              with confirmation as to whether we process your personal data. In
              the event that we do process your personal data, we will provide
              you with (i) access to the personal data, (ii) the purpose of
              processing, (iii) the categories of personal data concerned, (iv)
              the recipients or categories of recipients to whom the personal
              data have been or will be disclosed, (v) where possible, the
              envisaged period for which the personal data will be stored, or,
              if not possible, the criteria used to determine that period, (vi)
              where the personal data are not collected from you, any available
              information as to their source, and (vii) the existence of any
              automated decision-making, such as profiling, and meaningful
              information about the logic involved. Please be sure to
              specifically articulate any requests you may have for Impartner to
              transmit your personal data to another organization pursuant to
              your data portability right.
            </p>
            <p>
              <strong>Rectification</strong> - Please select the “Rectification”
              button below to request that Impartner correct or complete any
              information in our systems that you believe is inaccurate or
              incomplete.
            </p>
            <p>
              <strong>Erasure</strong> - Please select the “Erasure” button
              below to request that Impartner erase your personal data in
              accordance with applicable law.
            </p>
            <p>
              <strong>Restrict Processing</strong> - Please select the “Restrict
              Processing” button below to request that Impartner restrict the
              processing of your personal data in accordance with applicable
              law.
            </p>

            <section className="mt-4">
              <p>
                <u>
                  Please select the type of request you would like to submit
                </u>
              </p>
              <div className="list d-flex flex-sm-row flex-column">
                <div className="me-3">
                  <Link
                    className="selfLink"
                    to={`EU/access-portability`}
                    target="_blank"
                  >
                    <Button variant="primary"> Access / Portability </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`EU/rectification`}
                    target="_blank"
                  >
                    <Button variant="primary"> Rectification </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link className="selfLink" to={`EU/erasure`} target="_blank">
                    <Button variant="primary"> Erasure </Button>
                  </Link>
                </div>
                <div className="me-3 mt-3 mt-sm-0">
                  <Link
                    className="selfLink"
                    to={`EU/restrictprocessing`}
                    target="_blank"
                  >
                    <Button variant="primary"> Restrict Processing </Button>
                  </Link>
                </div>
              </div>
            </section>
          </section>
        )}
      </Container>
    </>
  );
}
