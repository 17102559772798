import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
import RequestTypeIntro from "../shared/components/requestTypeIntro";
function Erasure() {
  return (
    <div>
      <SrrHeader
        heading="Erasure Personal Information"
        breadcrumb_path1="IMPARTNER TRUST CENTER"
        breadcrumb_path2="Erasure"
      />

      <RequestTypeIntro requestType="erasure" />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_IMPARTNER_SRR_CONFIG_EU_ERASURE_MYSELF}
          pagId={
            process.env.REACT_APP_IMPARTNER_SRR_CONFIG_EU_ERASURE_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default Erasure;
